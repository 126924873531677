'use strict';

const mediaQuery = window.matchMedia('(max-width: 1023.98px)');
/**
 * Handles adding the background mask on hover of menu items
 */
function backgroundMask() {
    var isDesktop = function (element) {
        return (
            $(element)
                .parents('.menu-toggleable-left')
                .css('position') !== 'fixed'
        );
    };

    var pageHeight = $('body').height();
    var menuEnterTimer;
    var menuLeaveTimer;
    var delay = 400;
    $('.open-menu-pagemask').height(pageHeight);
    $('.navbar-nav.primary > .nav-item').hover(
        function (event) {
            if (isDesktop(event.target)) {
                var targetMenuID = $(this)
                    .find('.nav-primary')
                    .data('target');
                if (!targetMenuID) {
                    return;
                }

                clearTimeout(menuLeaveTimer);
                $('.navbar-nav.primary > .nav-item').removeClass('hover-disabled');
                menuEnterTimer = setTimeout(function () {
                    $(targetMenuID + '-desktop').parents('li.primary-nav-item').addClass('hover-disabled');
                    $('li.primary-nav-item').removeClass('tabActive');
                    $('li.primary-nav-item .secondary.collapse ').removeClass('accDropdownClosed');
                    var targetMenuHeight = $(targetMenuID + '-desktop').outerHeight() + 74;
                    $(targetMenuID + '-desktop').css({ opacity: 0 });
                    $('.open-menu-menubackground').animate({ height: targetMenuHeight }, 200, 'linear');
                    $('.open-menu-menubackground').css({ display: 'block', height: targetMenuHeight });
                    $('.open-menu').removeClass('d-none');
                    $(targetMenuID + '-desktop').animate({ opacity: 1 }, 500);
                    var isPromoTile = $(targetMenuID + '-desktop').find('.promo-tile-element');
                    if (isPromoTile.length > 0 && targetMenuHeight <= isPromoTile.outerHeight()) {
                        setTimeout(function () {
                            $(targetMenuID + '-desktop').css('min-height', isPromoTile.outerHeight() + 60);
                            $('.open-menu-menubackground').css({ height: isPromoTile.outerHeight() + 60 });
                        }, 100);
                    } else {
                        $(targetMenuID + '-desktop').parents('li.primary-nav-item').addClass('hover-disabled');
                    }
                }, delay);
            }
        },
        function (event) {
            if (isDesktop(event.target)) {
                $('.open-menu').addClass('d-none');
            }
        }
    );

    $('.navbar-nav.primary > .nav-item').mouseleave(function () {
        clearTimeout(menuEnterTimer);
        menuLeaveTimer = setTimeout(function () {
            $(this).removeClass('hover-disabled');
        }, delay);
    });
}

/**
 *Adds hover class to primary category
 */
function primaryCategory() {
    $('.navbar-nav .nav-item').hover(
        function () {
            $(this)
                .find('.nav-primary')
                .addClass('category-border');
        },
        function () {
            $(this)
                .find('.nav-primary')
                .removeClass('category-border');
        }
    );
}

/**
 * Toggle menu
 */
function toggleMenu() {
    var isDesktop = function () {
        return (
            $('.navbar-nav.primary > .nav-item')
                .parents('.menu-toggleable-left')
                .css('position') !== 'fixed'
        );
    };

    if (isDesktop()) {
        $('.navbar-nav')
            .find('.top-category')
            .detach();
        $('.navbar-nav')
            .find('.nav-menu')
            .detach();
        $('.navbar-nav')
            .find('.show')
            .removeClass('show');

        // Collapsing primary and secondary navigation
        $('.navbar-nav .nav-item')
            .find('.nav-primary')
            .removeClass('category-border')
            .attr('aria-expanded', 'false')
            .addClass('collapsed');
        $('.navbar-nav .nav-item .secondary')
            .find('.nav-secondary')
            .attr('aria-expanded', 'false')
            .addClass('collapsed');

        // Closing the menu
        $('.menu-toggleable-left').removeClass('in');

        // Closing the modal background
        $('.modal-background').hide();
    }
}

$(function () {
    if (mediaQuery.matches) {
        $('.other-header').on('click', function () {
            $('.secondary').addClass('secondary-hide');
            setTimeout(function () {
                $('.secondary').removeClass('secondary-hide').removeClass('show');
            }, 1000);
        });
    }
    backgroundMask();
    primaryCategory();

    var megaMenu = document.getElementById('category-accordion-container');
    $('li.primary-nav-item div.d-lg-block .activity_data a.nav-secondary').addClass('d_activity_title');

    /**
     * determines if the item is a top-level one
     * @param {Object} item - link item reference
     * @returns {boolean} - item is top level link or not
    */
    function isTopLevel(item) {
        return $(item).is('#category-accordion-container li.primary-nav-item > div a.nav-primary');
    }

    /**
     * finds the adjacent top level item
     * @param {Object} item - link item reference
     * @param {string} dir - direction next/prev
     * @returns {Object} - adjacent item next/prev
    */
    function adjacentTopLevelItem(item, dir) {
        var li = item.parentNode.parentNode; // <li />
        var adjacentLi = (dir === 'next') ?
                        $(li).next()[0] :
                        $(li).prev()[0];
        var adjacentItem = adjacentLi && $('a', adjacentLi)[0];
        return adjacentItem;
    }

    /**
     * determines if the item has a dropdown
     * @param {Object} item - link item reference
     * @returns {boolean} - check if link has submenu
    */
    function hasDropdown(item) {
        return $(item.parentNode.parentNode).find('.nav-link').length > 0;
    }

    /**
     * Opens the dropdown
     * @param {Object} item - link item reference
    */
    function openDropdown(item) {
        $(item).attr('aria-expanded', 'true');
        $(item.parentNode.parentNode).addClass('tabActive');
        $('li.primary-nav-item').removeClass('accDropdownClosed');
        // ...and focus the first item
        setTimeout(function () {
            $(item.parentNode.parentNode).find('div.d-lg-block').find('a:not(.d-lg-none)')[0].focus();
            $(item.parentNode.parentNode).find('div.d-lg-block').find('.secondary').removeClass('accDropdownClosed');
        }, 100);
    }

    /**
     * finds the next or prev dropdown item
     * @param {Object} item - link item reference
     * @param {string} dir - direction next/prev
     * @returns {Object} - adjacent item next/prev
    */
    function adjacentDropdownItem(item, dir) {
        var adjacentDropItem;
        var li = $(item).parents('li.primary-nav-item');

        var liElmIndex = $(li).find('div.d-lg-block').find('[tabindex="0"]:not(.d-lg-none):not(.d_activity_title):not(.close-menu-link)').index(item);

        if (dir === 'next') {
            adjacentDropItem = $(li).find('div.d-lg-block').find('[tabindex="0"]:not(.d-lg-none):not(.d_activity_title):not(.close-menu-link)')[liElmIndex + 1];
        } else if (dir === 'prev') {
            adjacentDropItem = $(li).find('div.d-lg-block').find('[tabindex="0"]:not(.d-lg-none):not(.d_activity_title):not(.close-menu-link)')[liElmIndex - 1];
        }
        return adjacentDropItem;
    }

    /**
     * Closing dropdown of submenu links
     * @param {Object} item - link item reference
     * @returns {Object} - top level item to reselect
    */
    function closeDropdown(item) {
        var topLevelItem = $(item).parents('li.primary-nav-item').find('a.nav-primary.d-lg-block')[0];
        $('li.primary-nav-item').removeClass('tabActive');
        $('.open-menu').addClass('d-none');
        $(item.parentNode.parentNode).parents('.secondary').addClass('accDropdownClosed');
        return topLevelItem;
    }

    /**
     * Adding event listener on media query changes
     * @param {Object} item - link item reference
     * @returns {boolean} - checked for item to be in first position
    */
    function isFirstDropItem(item) {
        var li = $(item).parents('li.primary-nav-item');
        var firstInDrop = $(li).find('div.d-lg-block').find('a:not(.d-lg-none)')[0];
        return firstInDrop === item;
    }

    /**
     * Adding event listener on media query changes
     * @param {Object} item - link item reference
     * @returns {boolean} - checked for item to be in last position
    */
    function isLastDropItem(item) {
        var li = $(item).parents('li.primary-nav-item');
        var lastInDrop = $(li).find('div.d-lg-block').find('a:not(.d-lg-none)').last();
        var liElmIndex = $(li).find('div.d-lg-block').find('[tabindex="0"]:not(.d-lg-none):not(.d_activity_title):not(.close-menu-link)').index(item);
        var adjacentDropItem = $(li).find('div.d-lg-block').find('[tabindex="0"]:not(.d-lg-none):not(.d_activity_title):not(.close-menu-link)')[liElmIndex];
        return lastInDrop[0] === adjacentDropItem;
    }

    /**
     * Keyboard stuff
     * @param {Object} keyVent - key reference
    */
    function keyboardHandler(keyVent) {
        var target = keyVent.target;
        var which = keyVent.which;

        if (which === 39) { // RIGHT
            if (isTopLevel(target)) {
                // top level item
                var nextTopItem = adjacentTopLevelItem(target, 'next');
                if (nextTopItem) {
                    keyVent.preventDefault();
                    nextTopItem.focus();
                }
            }
        } else if (which === 37) { // LEFT
            if (isTopLevel(target)) {
                // top level item
                var prevTopItem = adjacentTopLevelItem(target, 'prev');

                if (prevTopItem) {
                    keyVent.preventDefault();
                    prevTopItem.focus();
                }
            }
        } else if (which === 38) { // UP
            if (!isTopLevel(target)) {
                if (isFirstDropItem(target)) {
                    keyVent.preventDefault();
                    var top = closeDropdown(target);
                    setTimeout(function () {
                        top.focus();
                        $(top).attr('aria-expanded', 'false');
                    }, 0);
                } else {
                    var prevDropAnchor = adjacentDropdownItem(target, 'prev');

                    if (prevDropAnchor) {
                        keyVent.preventDefault();
                        prevDropAnchor.focus();
                    }
                }
            }
        } else if (which === 40) { // DOWN
            if (isTopLevel(target) && hasDropdown(target)) {
                // top level item w/ dropdown -- open dropdown
                openDropdown(target);
            } else if (!$(target).hasClass('d-lg-none')) {
                // dropdown item
                var nextDropItem = adjacentDropdownItem(target, 'next');

                if (nextDropItem) {
                    keyVent.preventDefault();
                    nextDropItem.focus();
                }
            }
        } else if (which === 27) { // ESCAPE
            if (!isTopLevel(target)) {
                var topper = closeDropdown(target);
                setTimeout(function () {
                    topper.focus();
                }, 0);
            }
        } else if (which === 32) { // SPACE
            if (isTopLevel(target) && hasDropdown(target)) {
                // top level item w/ dropdown -- open dropdown
                openDropdown(target);
            }
        } else if (which === 9 && keyVent.shiftKey) { // SHIFT + TAB
            if (!isTopLevel(target) && isFirstDropItem(target)) {
                keyVent.preventDefault();
                var topA = closeDropdown(target);
                setTimeout(function () {
                    topA.focus();
                }, 0);
            }
        } else if (which === 9) { // TAB
            if (!isTopLevel(target) && isLastDropItem(target)) {
                keyVent.preventDefault();
                var topItem = closeDropdown(target);
                setTimeout(function () {
                    topItem.focus();
                }, 0);
            }
        }
    }

    /**
         * Keyboard stuff
         * @param {Object} keyVent - key reference
        */
    function keyboardMobHandler(keyVent) {
        var target = keyVent.target;
        var which = keyVent.which;

        if (which === 13) { // ENTER
            if ($(target).hasClass('nav-primary')) {
                var targetInnerLinkCont = $(target).attr('data-target');
                $(targetInnerLinkCont).find('.other-header')[0].focus();
                $(targetInnerLinkCont).attr('aria-hidden', 'false');
            } else if ($(target).hasClass('other-header')) {
                var parentTargetLink = '#' + $(target).parent().attr('id');
                setTimeout(function () {
                    $(target).trigger('click');
                    $('[data-target="' + parentTargetLink + '"]')[0].focus();
                }, 0);
            } else if ($(target).hasClass('close-menu-link')) {
                setTimeout(function () {
                    $(target).trigger('click');
                }, 0);
            } else if ($(target).hasClass('nav-secondary')) {
                var targetL3Links = $(target).attr('data-target');
                if ($(target).attr('aria-expanded') === 'false') {
                    setTimeout(function () {
                        $('.pdMobileMegaNavWrapper').find(targetL3Links).find('a.nav-link')[0].focus();
                    }, 0);
                }
            } else {
                setTimeout(function () {
                    if (sessionStorage.getItem('aLaunch_megaNav') !== null) {
                        var targetL2Links = '#subcategories-' + sessionStorage.getItem('aLaunch_megaNav');
                        $(targetL2Links).find('.other-header')[0].focus();
                    } else if ($(target).hasClass('navbar-toggler')) {
                        $('.close-menu.d-lg-none .logo a')[0].focus();
                    }
                }, 0);
            }
        }
    }

    if (mediaQuery.matches) {
        var navigationContainer = document.getElementById('navigation-container');
        $(navigationContainer).delegate('li a, button.navbar-toggler, li.other-header, span.close-menu-link', 'keydown', keyboardMobHandler);
        $(megaMenu).delegate('li a', 'keydown', keyboardMobHandler);
    }

    $(megaMenu).delegate('li a, span.activity_title, span, h4', 'keydown', keyboardHandler);
});

if ($('.nav.navbar-nav.primary li.nav-item').hasClass('saleRed')) {
    $('li.nav-item.saleRed .other-header').css('color', '#d21e2f');
}


$('.navbar-nav.primary > .nav-item').keydown(
    function (event) {
        var code = event.keyCode || event.which;
        if (code === 40) {
            var targetMenuID = $(this)
                    .find('.nav-primary')
                    .data('target');
            var targetMenuHeight = $(targetMenuID + '-desktop').outerHeight() + 74;
            $(targetMenuID + '-desktop').css({ opacity: 0 });
            $('.open-menu-menubackground').animate({ height: targetMenuHeight }, 200, 'linear');
            $('.open-menu-menubackground').css({ display: 'block', height: targetMenuHeight });
            $('.open-menu').removeClass('d-none');
            $(targetMenuID + '-desktop').animate({ opacity: 1 }, 500);
        }
    });

if (mediaQuery.matches) {
    $(document).on('click', '.modal-background, .close-menu-link', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });
}

$('#category-accordion-container li .nav-secondary').off().click(function () {
    var $that = $(this);
    var $checkShowClass;
    if ($('.pdMegaNav').length > 0) {
        $checkShowClass = !$that.parents('.experience-region').next().hasClass('show');
    } else {
        $checkShowClass = !$that.next().hasClass('show');
    }
    if ($checkShowClass) {
        setTimeout(function () {
            $('#category-accordion-container li .nav-secondary').addClass('collapsed');
            $that.removeClass('collapsed');
        }, 100);
    } else {
        setTimeout(function () {
            $that.addClass('collapsed');
        }, 100);
    }
});

$(document).ready(function () {
    var $headerTopStripHeight = $('.global-header .banner').height();
    var $prevScrollValue = 0;
    var $curScrollValue = 0;
    $(window).on('load', function () {
        setTimeout(function () {
            $('#nebula_div_btn').css('z-index', '2');
        }, 500);
    }).on('scroll', function () {
        var $windowTopScrollVal = $(window).scrollTop();
        if ($windowTopScrollVal > ($headerTopStripHeight + 30)) {
            $('body').css('paddingTop', '82px');
            $('body').addClass('stickyHeader fixedTop');
        } else {
            $('body').removeClass('stickyHeader fixedTop');
            $('body').css('paddingTop', '0');
        }

        if ($windowTopScrollVal > $headerTopStripHeight * 2) {
            $('body').removeClass('fixedTop');
            $('body').addClass('hideHeader');
        } else {
            $('body').removeClass('hideHeader');
            $('body').removeClass('hideBanner');
        }

        if ($windowTopScrollVal > $headerTopStripHeight * 4) {
            $('body').addClass('hideBanner');
        }

        $curScrollValue = window.pageYOffset;

        if (!$('body').hasClass('fixedTop')) {
            if ($prevScrollValue - $curScrollValue < 0) {
                $('body').removeClass('navExpanded');
            } else if ($prevScrollValue - $curScrollValue > 0) {
                $('body').addClass('navExpanded');
            }

            $prevScrollValue = $curScrollValue;
        }
    });
});

window.onload = function () {
    var currURL;
    var targetLink;

    if ($('#cdp-page-indicator, #catLanding-indicator').length >= 1) {
        currURL = window.location.protocol + '//' + window.location.host + window.location.pathname;
        targetLink = $('.main-menu a.nav-link[href="' + currURL + '"], a.nav-primary-cta[href="' + currURL + '"]');
        targetLink.addClass('highlighted');
        targetLink.attr('aria-current', 'page');
        targetLink.parents('li.primary-nav-item').addClass('highlighted');
        targetLink.parents('li.primary-nav-item').attr('aria-current', 'page');
        targetLink.parents('li.primary-nav-item').find('.nav-primary').attr('aria-current', 'page');
    } else if ($('#pdp-redesign').length >= 1) {
        var $breadcrumbItems = $('.breadcrumb').find('.breadcrumb-item-new');
        currURL = $breadcrumbItems.last().find('a').attr('href');
        targetLink = $('.main-menu a.nav-link[href$="' + currURL + '"], a.nav-primary-cta[href$="' + currURL + '"]');
        targetLink.addClass('highlighted');
        targetLink.attr('aria-current', 'page');
        targetLink.parents('li.primary-nav-item').addClass('highlighted');
        targetLink.parents('li.primary-nav-item').attr('aria-current', 'page');
        targetLink.parents('li.primary-nav-item').find('.nav-primary').attr('aria-current', 'page');
    }
};

$(document).ready(function () {
    $('#sweatcollective .experience-commerce_layouts-mobileGrid2r1c:first').css('text-align', 'center');

    // Added logic to handle shop all link display in mobile
    $('.pdMobileMegaNavWrapper .secondary .shopAll_title').each(function () {
        var shopAllLinkDomCopy = $(this).clone();
        $(this).parents('.pdMobileMegaNavWrapper').find('.pdShopAllMobileLink').addClass('showShopAllLinks')
        .html(shopAllLinkDomCopy);
    });

    $('.pdMobileMegaNavWrapper ul.isCollections').each(function () {
        $(this).parents('.collections_data').addClass('isCollections');
    });

    $('.pdMobileMegaNavWrapper li.mobLevel2Wrapper').each(function () {
        if ($(this).find('ul').length === 0) {
            $(this).addClass('d-none');
        }
    });

    $('.pdMobileMegaNavWrapper li.mobLevel2ContentWrapper').each(function () {
        if ($(this).find('.promo-tile-element').length === 0) {
            $(this).addClass('d-none');
        }
    });
});

/**
 * Adding event listener on media query changes
 */
function addMediaQueryListener() {
    mediaQuery.addListener(toggleMenu);
}

module.exports = {
    backgroundMask: backgroundMask,
    primaryCategory: primaryCategory,
    addMediaQueryListener: addMediaQueryListener
};
